import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"insurance"} />
		<Helmet>
			<title>
				Venta-Trans Europe - Freight Forwarding Company
			</title>
			<meta name={"description"} content={"Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of companies throughout Europe and CIS since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the high-quality and low-cost service that we provide."} />
			<meta property={"og:title"} content={"Venta-Trans Europe - Freight Forwarding Company"} />
			<meta property={"og:description"} content={"Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of companies throughout Europe and CIS since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the high-quality and low-cost service that we provide."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/og_img.jpg?v=2021-04-21T14:38:42.609Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/favicon-32x32.png?v=2021-04-22T09:26:54.780Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<meta name={"robots"} content={"noindex,nofollow"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfBody"} rawKey={"608142d6ab4dd832c0755f1a"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
			<script src={"https://www.googletagmanager.com/gtag/js?id=G-118BTS02HM"} async={true} place={"endOfHead"} rawKey={"6082f9b76cd311d618e2da67"} />
			<script place={"endOfHead"} rawKey={"6082f9d0a29b8aa4421cb038"}>
				{"window.dataLayer = window.dataLayer || [];\nfunction gtag(){dataLayer.push(arguments);}\ngtag('js', new Date());\ngtag('config', 'G-118BTS02HM');"}
			</script>
			<script src={"https://fast.wistia.com/embed/medias/ivn4prep36.jsonp"} async={true} place={"endOfHead"} rawKey={"6083012406cc56de18db89d6"} />
			<script async={true} src={"https://fast.wistia.com/assets/external/E-v1.js"} place={"endOfHead"} rawKey={"608301398ecb4f30a2c73278"} />
		</RawHtml>
	</Theme>;
});